* {
	margin: 0;
	padding: 0;
	font-family: 'Lunchtype';
}
@font-face {
    font-family: 'centabel';
    src: url('../fonts/centabel.ttf') format('ttf'),
         url('../fonts/centabel.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'Lunchtype';
    src: url('../fonts/Lunchtype21-Regular.ttf') format('ttf'),
         url('../fonts/Lunchtype21-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'cooper';
    src: url('../fonts/cooper.ttf') format('ttf'),
         url('../fonts/cooper.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}
.wrapper{
	background: #131216;
	display: flex;
	flex-direction: column;
	align-items: center;
}
.container{
	max-width: 1280px;
	height: 100%;
	width: 100%;
	position: relative;
}
.header{
	background: #1d1c20;
	height: 160px;
	width: 100%;
	position: fixed;
	display: flex;
	justify-content: center;
	z-index: 10;
	.menu {
		position: absolute;
		top: 8px;
		right: 24px;
		display: inline-flex;
		justify-content: space-between;
		align-items: center;
		height: 80px;
		width: 320px;
		.menu-item{
			width: 56px;
			height: 56px;
			&:hover {
				background-size: 96%;
			}
		}
		.discord{
			background: url('../images/discord.png')no-repeat center / contain;
		}
		.twitter{
			background: url('../images/twitter.png')no-repeat center / contain;
		}
		.insta{
			background: url('../images/insta.png')no-repeat center / contain;
		}
		.facebook{
			background: url('../images/facebook.png')no-repeat center / contain;
		}
		.youtube{
			background: url('../images/youtube.png')no-repeat center / contain;
		}
	}
	.logo__wrap {
		position: absolute;
		top: 24px;
		left: 48px;
		height: 192px;
		width: 96px;
		.logo{
			position: relative;
			height: 100%;
			width: 100%;
			background: url('../images/logo.png')no-repeat center / contain;
			&:before{
				content: '';
				position: absolute;
				border: 6px solid #abb3be;
				border-radius: 2px;
				top: 60%;
				width: 30px;
				right: 104px;
			}
			&:after{
				content: '';
				position: absolute;
				top: 60%;
				width: calc(1280px - 164px);
				left: 104px;
				border: 6px solid #abb3be;
				border-radius: 2px;
			}
		}
	}
	.title {
		position: absolute;
		bottom: 24px;
		font-family: 'cooper';
		left: 160px;
		color: #fff8e6;
		font-size: 40px;
	}

}
.info{
	color: #ea9f1a;
	padding-top: 242px;
	display: flex;
	flex-direction: column;
	align-items: center;
	.video{
		width: 560px;
		height:315px;
	}
	.info__header{
		font-size: 32px;
		font-family: 'centabel';
	}
	.info__title{
		padding: 20px 0 10px 0;
	}
	.info__description{
		margin-top: 10px;
		font-size: 22px;
	}
	.item__list{
		padding-left: 20px;
		.info__description-item {
			font-family: 'Lunchtype';
			padding: 8px 0;
		}
	}
}
.contacts{
	color: #ea9f1a;
	margin-top: 48px;
	padding-bottom: 70px;
	display: flex;
	justify-content: space-around;
	.contacts__mail{
		font-size: 24px;
	}
	.contacts__subscribe{
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		font-size: 20px;
		.subscribe__button{
			width: 80px;
			color: #ea9f1a;
			background: #382929;
			border: none;
			height: 24px;
			margin-top: 8px;
			border-radius: 8px;
			transition: 0.2s;
			&:hover{
				cursor: pointer;
				transition: 0.2s;
				box-shadow: inset 0px 0px 5px 0px rgba(0,0,0,0.5);
			}
		}
		.subscribe__input{
			background: #382929;
			color: gray;
			border: none;
			border-radius: 3px;
			height: 24px;
			margin: 5px 0;
			padding-left: 5px;
		}
	}
}
.footer{
	text-align: center;
	height: 40px;
	line-height: 40px;
	.footer__title{
		color: rgba(234, 159, 26, 0.5);
	}
}

@media screen and (max-width: 761px) {
	.header{
		height: 280px;
		position: relative;
		.menu {
			position: absolute;
			right: 0;
			top: calc(100% - 80px);
			justify-content: space-around;
			width: 100%;
			.menu-item{
				width: 48px;
				height: 48px;
			}
		}
		.logo__wrap {
			position: absolute;
			left: 5px;
			top: 10px;
			height: 192px;
			width: 96px;
			.logo{
				position: relative;
				&:before{
					display: none;
				}
				&:after{
					content: '';
					position: absolute;
					width: 199px;
				}
			}
		}
		.title {
			position: absolute;
			bottom: 160px;
			font-family: 'cooper';
			left: 110px;
			color: #fff8e6;
			font-size: 32px;
		}
	}
	.info{
		padding-top: 32px;
		.info__header{
			font-size: 18px;
			text-align: center;
		}
		.info__title{
			font-size: 14px;
			text-align: center;
		}
		.video{
			width: 300px;
			height: 170px;
		}
		.info__description{
			width: 90%;
			font-size: 14px;
		}
	}
	.contacts{
		flex-direction: column;
		align-items: center;
		width: 256px;
		.contacts__mail{
			font-size: 16px;
			display: inline-flex;
			justify-content: space-between;
			width: 100%;
		}
		.contacts__subscribe{
			padding-top: 10px;
			width: 100%;
			font-size: 16px;
		}
	}
	.footer{
		font-size: 12px;
	}
}